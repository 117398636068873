import * as client_hooks from '../../../../_commons/hooks-client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63')
];

export const server_loads = [0,2,4,6,7];

export const dictionary = {
		"/(app)": [~8,[2],[3]],
		"/(app)/companies": [9,[2],[3]],
		"/(app)/companies/[companyId=guid]/aides": [~11,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/aides/[slug]": [~12,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/audit/[requestId]": [~13,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/files": [~14,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/newsletters/[...newsletterId]/results/[newsletterResultId]": [~16,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/newsletters/[...newsletterId]": [~15,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/notes": [~17,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/reports": [18,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/reports/roadmap/[...roadmapId]/aide/[slug]": [~22,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/reports/roadmap/[...roadmapId]/review": [~23,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/reports/roadmap/[...roadmapId]/settings": [~24,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/reports/roadmap/[...roadmapId]": [~21,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/reports/[requestId]": [~19,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/reports/[requestId]/settings": [~20,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/settings": [~25,[2,4,5],[3]],
		"/(app)/companies/[companyId=guid]/settings/billing": [26,[2,4,5],[3]],
		"/(app)/companies/[companyId=guid]/settings/company": [27,[2,4,5],[3]],
		"/(app)/companies/[companyId=guid]/settings/experts": [28,[2,4,5],[3]],
		"/(app)/companies/[companyId=guid]/settings/team": [~29,[2,4,5],[3]],
		"/(app)/companies/[companyId=guid]/[...requestId]": [~10,[2,4],[3]],
		"/(app)/faqs": [30,[2],[3]],
		"/(app)/faqs/[destination]": [~31,[2],[3]],
		"/(app)/firms": [~32,[2,6],[3]],
		"/(app)/firms/experts": [38,[2,6],[3]],
		"/(app)/firms/experts/[expertId]": [39,[2,6],[3]],
		"/(app)/firms/offers": [~40,[2,6],[3]],
		"/(app)/firms/offers/edit/[...offerId]": [~41,[2,6],[3]],
		"/(app)/firms/opportunities": [42,[2,6],[3]],
		"/(app)/firms/opportunities/[prospectAdId=guid]": [~43,[2,6],[3]],
		"/(app)/firms/opportunities/[prospectAdId=guid]/contacts/[...messageId]": [44,[2,6],[3]],
		"/(app)/firms/[id]": [~33,[2,6,7],[3]],
		"/(app)/firms/[id]/logs": [34,[2,6,7],[3]],
		"/(app)/firms/[id]/missions": [35,[2,6,7],[3]],
		"/(app)/firms/[id]/stats": [~36,[2,6,7],[3]],
		"/(app)/firms/[id]/users": [37,[2,6,7],[3]],
		"/(app)/forms": [45,[2],[3]],
		"/(app)/forms/supporting-documents": [47,[2],[3]],
		"/(app)/forms/variables/[...id]": [48,[2],[3]],
		"/(app)/forms/[id]": [46,[2],[3]],
		"/(app)/grants": [~49,[2],[3]],
		"/(app)/grants/archived": [~51,[2],[3]],
		"/(app)/grants/duplicates": [~52,[2],[3]],
		"/(app)/grants/duplicates/[id]": [~53,[2],[3]],
		"/(app)/grants/financers": [~54,[2],[3]],
		"/(app)/grants/preview/[slug]": [~55,[2],[3]],
		"/(app)/grants/robots": [~56,[2],[3]],
		"/(app)/grants/robots/[scraperId]": [~57,[2],[3]],
		"/(app)/grants/[id]": [~50,[2],[3]],
		"/(auth)/info": [~62],
		"/(app)/settings/profile": [58,[2],[3]],
		"/(app)/settings/security": [59,[2],[3]],
		"/(app)/settings/users": [60,[2],[3]],
		"/(auth)/signin": [~63],
		"/(app)/stats": [~61,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';